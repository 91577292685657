@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "Poppins-Medium";
  src: url("../src/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Grotesque";
  src: url("../src/fonts/TomatoGrotesk-Bold.otf");
}

@font-face {
  font-family: "GrotesqueExtra";
  src: url("../src/fonts/TomatoGrotesk-ExtraBold.otf");
}

html, body { 
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
} 

#element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

#element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
